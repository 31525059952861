import { computed, Ref } from 'vue';

export default function useBillIdParser(id: Ref<string>) {
	return {
		chamber: computed(() => id.value.indexOf('s') === 0 ? 'Senate' : 'House'),
		number: computed(() => id.value.match(/\d+/ig)[0]),
		slug: computed(() => id.value.match(/(\w+)/)?.[0]),
		congress: computed(() => id.value.match(/\d+/ig)[1]),
		isConcurrent: computed(() => !!id.value.match(/con/)?.[0]),
		isResolution: computed(() => !!id.value.match(/res/)?.[0]),
	}
}