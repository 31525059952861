<template>
	<ul class="bill-progress">
		<li class="stage" :class="{passed: stage.date}" v-for="stage in model" :key="stage.name">
			<h6 class="em m0 center">
				{{stage.name}}
			</h6>
			<small class="center">
				{{stage.date && new Date(stage.date).toLocaleString('en-US', {
					day: 'numeric',
					month: 'short',
					year: 'numeric',
				})}}
			</small>
		</li>
		<li class="stage" :class="{
			passed: bill.vetoed || bill.enacted,
			enacted: bill.enacted,
			vetoed: bill.vetoed,
		}">
			<h6 class="em m0 center">
				{{bill.vetoed ? 'Vetoed' : 'Enacted'}}
			</h6>
			<small v-if="bill.enacted" class="center">
				{{new Date(bill.enacted).toLocaleString('en-US', {
					day: 'numeric',
					month: 'short',
					year: 'numeric',
				})}}
			</small>
			<small v-if="bill.vetoed" class="center">
				{{new Date(bill.vetoed).toLocaleString('en-US', {
					day: 'numeric',
					month: 'short',
					year: 'numeric',
				})}}
			</small>
		</li>
	</ul>
</template>

<script lang="ts">
import {computed} from 'vue'
import {Chamber} from 'domain/Constants'
import {Bill} from 'domain/schema/Bill.model'

export default {
	name: 'bill-progress',
	props: {
		bill: {type: Object, required: true},
	},
	setup(p: {bill: Bill}) {
		const startingChamber = p.bill.bill_type === 'hr' ? Chamber.House : Chamber.Senate

		const senatePassage = {
			name: `Passed ${Chamber.Senate}`,
			date: p.bill.senate_passage,
		}
		const housePassage = {
			name: `Passed ${Chamber.House}`,
			date: p.bill.house_passage,
		}
		const model = computed(() =>
			(startingChamber === Chamber.House) ? [housePassage, senatePassage] : [senatePassage, housePassage])

		return {
			startingChamber,
			model,
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.bill-progress {
	margin: 0 16px 12px 0
	flexXY(space-evenly, center)
	.stage {
		margin: 0
		wh(100%, 3em)
		position relative
		fleXY(center)
		color: $c-text-bg
		background: #aaa
		width: 100%
		&:after {
			content ''
			border-top: 1.5em solid transparent
			border-bottom: 1.5em solid transparent
			border-left: 15px solid #aaa
			absPos(0, 0, auto, 100%)
			z-index 2
		}
		&:before {
			content: ''
			border-top: 1.5em solid transparent
			border-bottom: 1.5em solid transparent
			border-left: 15px solid white
			absPos(0, 0, 0, calc(100% + 1px))
			z-index: 1
		}
		&:first-child {
			border-top-left-radius: 12px
			border-bottom-left-radius: 12px
		}
		&.passed {
			background: $c-bg
			&:after {
				border-left-color: $c-bg
			}
		}
		&.passed.enacted {
			background: $c-primary-1
			color: #242526
			&:after {
				border-left-color: $c-primary-1
			}
		}
		&.passed.vetoed {
			background: $c-rep
			color: #242526
			&:after {
				border-left-color: $c-rep
			}
		}
	}
}
</style>