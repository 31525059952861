<template>
	<div class="el-sponsor">
		<el-member-chip :id="sponsorId"/>
	</div>
</template>

<script lang="ts">
import ElMemberChip from 'src/components/member/el-member-chip.vue'

export default {
	name: 'el-sponsor',
	components: {ElMemberChip},
	props: {
		sponsorId: {type: String, required: true},
	},
}
</script>
