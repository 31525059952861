
import {Bill} from 'domain/schema/Bill.model'
import useEllisContext from 'src/useEllisContext'
import ElBillTags from './el-bill-tags.vue'
import ElSponsor from 'src/components/member/el-sponsor.vue'
import BillProgress from './bill-progress.vue'
import XmlBillViewer from 'src/components/bill/viewer/xml-bill-viewer.vue'
import useBillIdParser from './useBillIdParser'
import { computed } from 'vue'

export default {
	name: 'el-bill',
	components: {ElBillTags, ElSponsor, BillProgress, XmlBillViewer},
	props: {
		bill: {type: Object, required: true},
		metrics: {type: Object, default: () => ({})},
	},
	emits: ['loaded', 'favorite', 'tag'],
	setup(props: {bill: Bill, metrics: any}) {
		const {api, profile} = useEllisContext()
		const id = computed(() => props.bill.bill_id)
		const {congress, slug} = useBillIdParser(id)

		const latestActionDate = new Date(props.bill.latest_major_action_date).toLocaleString('en-US', {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
		})

		function foobar() {
			console.log("LOGGAOSGOS")
		}

		return {
			profile,
			latestActionDate,
			foobar,
		}
	},
	methods: {
		// TODO: Copy Link to Clipboard
		generateShareLink() {
			return ''
		},
	},
}
