
import {computed} from 'vue'
import {Chamber} from 'domain/Constants'
import {Bill} from 'domain/schema/Bill.model'

export default {
	name: 'bill-progress',
	props: {
		bill: {type: Object, required: true},
	},
	setup(p: {bill: Bill}) {
		const startingChamber = p.bill.bill_type === 'hr' ? Chamber.House : Chamber.Senate

		const senatePassage = {
			name: `Passed ${Chamber.Senate}`,
			date: p.bill.senate_passage,
		}
		const housePassage = {
			name: `Passed ${Chamber.House}`,
			date: p.bill.house_passage,
		}
		const model = computed(() =>
			(startingChamber === Chamber.House) ? [housePassage, senatePassage] : [senatePassage, housePassage])

		return {
			startingChamber,
			model,
		}
	},
}
