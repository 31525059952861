<template>
	<div class="bill-container">
		<div class="el-bill card" v-if="bill.bill_id" :data-bill-id="bill.bill_id">
			<div :class="['indicator-active', {active: bill.active}]"
				:title="bill.active ? 'Active' : 'Inactive'"/>
			<header class="box">
				<div>
					<router-link :to="`/bills/${bill.bill_id}`">
						<h5 class="m0"><b>{{bill.number}}</b></h5>
					</router-link>
					<h5 class="m0">{{bill.congress}}th Congress</h5>
				</div>
				<div class="actions">
					<i title="favorite" :class="['lni favorite', `lni-star${metrics.favorite ? '-filled' : ''}`]"
						@click="$emit('favorite', {id: bill.bill_id, value: metrics.favorite})"/>
					<i title="share" :class="['lni', `lni-link`]"/>
				</div>
			</header>

			<section class="box">

				<article>
					<h3>{{bill.short_title}}</h3>
					<!-- <el-bill-tags :bill-id="bill.bill_id" :auto-tags="[bill?.primary_subject]" :user-tags="[]"/> -->
				</article>

				<am-annotation label="Sponsor">
					<el-sponsor :sponsor-id="bill.sponsor_id"/>
				</am-annotation>

				<article class="summary">
					<am-collapsible :min-height="200">
						<template v-slot:content>
							<p v-if="bill?.summary">{{bill.summary}}</p>
						</template>
					</am-collapsible>
				</article>

				<br><br>

				<am-annotation label="Status">
					<bill-progress :bill="bill"/>
					<h5 class="em m0">Latest Action</h5><br>
					<blockquote v-if="bill">
						<strong>{{latestActionDate}}</strong>
						: &nbsp;&nbsp; {{bill?.latest_major_action}}
					</blockquote>
				</am-annotation>

				<br><br><br>

				<am-collapsible>
					<template v-slot:header><h4 class="m0">Full Bill</h4></template>
					<template v-slot:content><xml-bill-viewer :bill-id="bill.bill_id"/></template>
				</am-collapsible>
			</section>

			<!-- <div class="timeline-container">
				<timeline v-if="bill && bill.actions" :events="bill.actions.map((a) => ({date: a.datetime, id: a.id}))">
					<bill-action v-for="(a, i) in bill.actions" :key="i" :action="a"/>
				</timeline>
			</div> -->
		</div>

		<br><br>

		<a href="#top">
			<button class="btn-secondary">Back To Top</button>
		</a>
	</div>
</template>

<script lang="ts">
import {Bill} from 'domain/schema/Bill.model'
import useEllisContext from 'src/useEllisContext'
import ElBillTags from './el-bill-tags.vue'
import ElSponsor from 'src/components/member/el-sponsor.vue'
import BillProgress from './bill-progress.vue'
import XmlBillViewer from 'src/components/bill/viewer/xml-bill-viewer.vue'
import useBillIdParser from './useBillIdParser'
import { computed } from 'vue'

export default {
	name: 'el-bill',
	components: {ElBillTags, ElSponsor, BillProgress, XmlBillViewer},
	props: {
		bill: {type: Object, required: true},
		metrics: {type: Object, default: () => ({})},
	},
	emits: ['loaded', 'favorite', 'tag'],
	setup(props: {bill: Bill, metrics: any}) {
		const {api, profile} = useEllisContext()
		const id = computed(() => props.bill.bill_id)
		const {congress, slug} = useBillIdParser(id)

		const latestActionDate = new Date(props.bill.latest_major_action_date).toLocaleString('en-US', {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
		})

		function foobar() {
			console.log("LOGGAOSGOS")
		}

		return {
			profile,
			latestActionDate,
			foobar,
		}
	},
	methods: {
		// TODO: Copy Link to Clipboard
		generateShareLink() {
			return ''
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.bill-container {
	flexXY(flex-start, center)
	flex-direction: column
}

.el-bill {
	max-width: 50em
	header {
		flexXY(space-between, center)
		& > div {
			flexXY(flex-start, center)
		}
		a {
			text-decoration: none
			color: inherit
		}
		h5, i {
			padding: 8px 12px
		}
		h5:first-child {padding-left: 0}
		h5:first-child {
			border-right: 2px solid #aaa
		}

		.actions {
			margin-right: 12px
			i {
				cursor: pointer
				color: #888
				&.favorite {
					color: #EEAF1B
					&.lni-star {
						color: #777
					}
				}
			}
		}
	}

	section {
		h3 {
			line-height: 1.4em
		}
	}

	article.summary {
		p:first-child::first-letter {
			color: lighten($c-bg, 30%)
			float: left
			font-size: 4rem
			line-height: 4rem
			padding-top: 4px
			padding-right: 8px
			padding-left: 3px
		}
	}

	a {
		text-decoration: underline
		color: #aaa
	}
}
</style>