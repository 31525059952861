<template>
	<div v-if="!!tag && tag !== ''" class="ellis-tag">
		<span :title="tag" @click="triggerTagSearch">{{tag}}</span>
		<i v-if="!readOnly" class="fa fa-times" @click="del"/>
	</div>
</template>

<script lang="ts">
import useEllisContext from 'src/useEllisContext'

export default {
	name: 'ellis-tag',
	props: {
		entityId: {
			type: String,
			required: true,
		},
		tag: {
			type: String,
			default: null,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const {api, store} = useEllisContext()
		const del = async (tagArgs) => {
			const pathArgs = tagArgs.from.split('/')
			if(pathArgs[0].toUpperCase() !== 'BILL') throw new Error('Unsupported tag target')
			await api.bill.deleteTag({entity: pathArgs[0], id: pathArgs[1], tag: tagArgs.tag})
			store.removeTag({billId: pathArgs[1], tag: tagArgs.tag})
		}

		return {
			del,
		}
	},
	methods: {
		triggerTagSearch() {
			this.$emit('search', {query: this.tag})
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.ellis-tag
	flexXY(space-between, center)
	border-radius: 4px
	padding: 3px 8px
	border: 1px solid #ccc
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis
	margin-right: 8px
	cursor: pointer
	span
		font-size: 0.8em
		word-wrap none
		padding-right: 6px
	i
		border-left: 1px solid #ccc
		padding: 0
		padding: 0 8px
		font-size: 0.86em
		cursor: pointer
		&:last-child
			padding-right: 0
</style>

