<template>
	<div class="bill-tags">
		<el-bill-tag v-for="t in autoTags" :key="t" :tag="t" :entity-id="billId" :read-only="true"
			@search="triggerSearch"/>
		<el-bill-tag v-for="t in metrics?.tags" :key="t"
			:tag="t"
			:entity-id="billId"
			@search="triggerSearch"/>
		<input class="new-tag" type="text" v-model="tag" placeholder="New Tag" @keyup.enter="addBillTag">
	</div>
</template>

<script lang="ts">
import ElBillTag from 'src/components/bill/el-bill-tag.vue'
import useEllisContext from 'src/useEllisContext'
import { computed, ref, watch } from 'vue'

export default {
	name: 'bill-tags',
	components: {ElBillTag},
	props: {
		billId: {
			type: String,
			required: true,
		},
		autoTags: {
			type: Array,
			default: () => [],
		},
		userTags: {
			type: Array,
			default: () => [],
		},
	},
	setup(props) {
		const {api, store, profile} = useEllisContext()
		const tag = ref(null)

		const addTag = async (to: string) => {
			const pathArgs = to.split('/')
			if (pathArgs[0].toUpperCase() !== 'BILL') throw new Error('Unsupported tag target')
			await api.bill.tag({entity: pathArgs[0], id: pathArgs[1], tag: tag.value})
			store.setBillTags(pathArgs[1], (tags) => [...tags, tag.value])
			tag.value = null
		}

		const addBillTag = () => addTag(`bill/${props.billId}`)

		const metrics = computed(() => store.getMetrics(props.billId))

		watch(profile, (p) => {
			console.log(p)
		})

		return {
			tag,
			metrics,
			profile,
			addBillTag,
		}
	},
	methods: {
		triggerSearch(e) {
			console.log('SEARCH', e)
			// this.$store.dispatch('billSearch', event.query)
			// 	.then((results) => this.$router.push({
			// 		name: 'bill-search-results',
			// 		query: {q: event.query},
			// 		params: {results, query: event.query},
			// 	}))
		},
	},
	created() {
		this.$watch('profile.name', (name) => name && this.$store.dispatch('billMetrics/getTags', {billId: this.billId}))
	},
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.bill-tags {
	flexXY(flex-start, center)
	padding: 12px 0
	flex-wrap: wrap
	input {
		border: none
		background: none
		line-height: 24px
		height: 24px
		width: 160px
		border-bottom: 2px solid #AECFDF
	}
}
</style>

