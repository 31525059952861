<template>
	<div class="el-bill-viewer box">
		<el-bill v-if="bill?.bill_id" :bill="bill" :metrics="metrics"
			@loaded="trackInterest"
			@favorite="toggleFavorite"/>
	</div>
</template>

<script lang="ts">
import ElBill from './el-bill.vue'
import useEllisContext from 'src/useEllisContext'
import { computed } from 'vue'
import {State, stored} from '../../vuex'
import { Bill } from '../../vuex/bill.module'
import { useStore } from 'vuex'

export default {
	name: 'el-bill-viewer',
	components: {ElBill},
	props: {
		billId: {type: String, required: true},
	},
	setup(p: {billId: string}) {
		const {api, profile} = useEllisContext()
		const store = useStore()

		const bill = stored<Bill, State>(({getters}) => getters['bill/bill'](p.billId),
			({commit}) => api.bill.get(p.billId).then((bill) => commit('bill/save', bill)))

		async function favorite(bill_id: string) {
			const res = await api['bill/favorite:POST'](bill_id)
			res.status === 201 && store.commit('bill/favorite', bill_id)
		}

		async function unfavorite(bill_id: string) {
			const res = await api['bill/favorite:DELETE'](bill_id)
			res.status === 204 && store.commit('bill/unfavorite', bill_id)
		}

		const metrics = computed(() => store.getters['bill/metrics'](p.billId))

		function trackInterest(e) {
			console.info(`TODO: track interest`)
		}

		return {
			profile,
			metrics,
			bill,
			toggleFavorite: ({id, value}) => !value ? favorite(id) : unfavorite(id),
			trackInterest,
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.el-bill-viewer {
	flexXY(flex-start, center)
	flex-direction: column
}
</style>