
import useEllisContext from 'src/useEllisContext'

export default {
	name: 'ellis-tag',
	props: {
		entityId: {
			type: String,
			required: true,
		},
		tag: {
			type: String,
			default: null,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const {api, store} = useEllisContext()
		const del = async (tagArgs) => {
			const pathArgs = tagArgs.from.split('/')
			if(pathArgs[0].toUpperCase() !== 'BILL') throw new Error('Unsupported tag target')
			await api.bill.deleteTag({entity: pathArgs[0], id: pathArgs[1], tag: tagArgs.tag})
			store.removeTag({billId: pathArgs[1], tag: tagArgs.tag})
		}

		return {
			del,
		}
	},
	methods: {
		triggerTagSearch() {
			this.$emit('search', {query: this.tag})
		},
	},
}
