
import {computed, h, defineComponent} from 'vue'
import {computedAsync} from 'src/util/computed'
import useEllisContext from 'src/useEllisContext'
import xmljs from 'xml-js'
import './js-md.styl'

const JsMd = defineComponent({
	name: 'js-md',
	props: {js: {type: Object, required: true}},
	setup(props) {
		const keyTagMap = (key) => {
			key = key.toLowerCase()
			if(key.indexOf('dc:') === 0 || key[0] === '_') return 'meta'
			if(parseInt(key) > -1) return 'li'
			switch (key) {
				case 'metadata':
				case 'distribution-code': return 'meta'
				case 'legis-num': return 'h3'
				case 'legis-type': return 'h4'
				case 'current-chamber':
				case 'header': return 'h5'
				case 'official-title': return 'h6'
				case 'section':
				case 'subsection': return 'section'
				case 'quote': return 'em'
				case 'enum': return 'span'
				case 'text': return 'p'
				case 'toc': return 'ul'
				case 'toc-entry': return 'li'
				case 'action':
				case 'bill':
				case 'form':
				case 'paragraph':
				case 'resolution':
				case 'resolution-body':
				case 'subparagraph':
				case 'legis-body': return 'div'
				case 'congress':
				case 'session': return 'blockquote'
				default: return 'span'
			}
		}

		const keyClassMap = (key) => {
			switch(key.toLowerCase()) {
				case 'enum': return 'inline'
				case 'legis-num':
				case 'current-chamber':
				case 'action-date':
				case 'legis-type': return 'center'
				default: return null
			}
		}

		const renderBillNodes = (node, tag = 'article', key = 'js-md') => {
			const attrs = {
				class: [key, keyClassMap(key)],
			}
			const children = !node.e ? [] : node.e.map((child) => {
				return child.t === 'text' ? child.text : renderBillNodes(child, keyTagMap(child.n), child.n)
			})
			return h(tag, attrs, children)
		}

		return () => renderBillNodes(props.js)
	},
})

export default {
	name: 'xml-bill-viewer',
	components: {JsMd},
	props: {
		billId: {type: String, required: true},
	},
	setup(props) {
		const {api} = useEllisContext()
		const xml = computedAsync<string>(() => api.bill.getText(props.billId).catch(console.info))

		const parsed = computed(() => xml.value && xmljs.xml2js(xml.value, {
			alwaysChildren: true,
			compact: false,
			ignoreAttributes: true,
			ignoreCdata: true,
			ignoreComment: true,
			ignoreDeclaration: true,
			ignoreDoctype: true,
			ignoreInstruction: true,
			elementsKey: 'e',
			nameKey: 'n',
			typeKey: 't',
		}))

		return {
			xml,
			parsed,
		}
	},
}
